package ch.ethz.icr.growup.pfe.view

import js.objects.jso
import mui.material.*
import mui.material.styles.TypographyVariant.Companion.h2
import mui.material.styles.TypographyVariant.Companion.h5
import mui.system.responsive
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import web.cssom.FontStyle

val LandingView = FC<Props> {
    Card {
        CardHeader {
            title = react.Fragment.create { Typography {
                variant = h5

                Typography {
                    component = span
                    variant = h5

                    sx = jso { fontStyle = FontStyle.italic }
                    +"GROWᵁᴾ"
                }
                +" - Geographical Research On War, Unified Platform"
            }}
        }
        CardContent {
            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)

                Typography {
                    component = div
                    //                variant = h5

                    +"The aim of the "
                    Typography {
                        component = span
                        sx = jso { fontStyle = FontStyle.italic }
                        +"GROWᵁᴾ"
                    }
                    +" federated data platform is to provide management and access to disaggregated, integrated, spatially explicit, and user-friendly conflict-related data."
                }
                Typography {
                    component = div
                    //                variant = h5

                    +"Under View, the "
                    Typography {
                        component = span
                        sx = jso { fontStyle = FontStyle.italic }
                        +"GROWᵁᴾ"
                    }
                    +" Public Front End offers a visualization of settlement patterns of politically active ethnic groups around the world from 1945-2023. Additionally, it provides information about ethnic groups' access to executive government power, their involvement in civil war, federal administrative units, physical elevation, nightlight data, as well as population and GDP data by area."
                }

                Typography {
                    component = div
                    //                variant = h5

                    +"Under Read, the content of the EPR Atlas is provided in PDF format, allow easy access to easily readable format."
                }

                Typography {
                    component = div
                    //                variant = h5

                    +"Under Download, the "
                    Typography {
                        component = span
                        sx = jso { fontStyle = FontStyle.italic }
                        +"GROWᵁᴾ"
                    }
                    +" Research Front End offers research-ready data on ethnic groups and intrastate conflict. Our data is compiled from various sources and provided in group-year and country-year format... ready for statistical analysis!"
                }

                Typography {
                    component = div
                    //                variant = h5

                    +"Under Code, the "
                    Typography {
                        component = span
                        sx = jso { fontStyle = FontStyle.italic }
                        +"GROWᵁᴾ"
                    }
                    +" Coding Front End offers an effective mean of collaboratively collecting geo-referenced data."
                }

                Typography {
                    component = div
                    //                variant = h5

                    +"This project has received funding from the European Research Council (ERC) under the European Union’s Horizon 2020 research and innovation programme (ERC Advanced Grant agreement No.787478 NASTAC)"
                }

            }
        }
        CardActions {
            Button {
                size = Size.small
                +"Learn More"
            }
        }
    }
}
