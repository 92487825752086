package org.molap.series

import org.molap.index.IntegerRangeUniqueIndex
import org.molap.index.UniqueIndex
import kotlin.reflect.KClass

class IntegerSeries : AbstractSeries<Int, Int?>, MutableSeries<Int, Int?> {
    override val name: Any?
    private val available: BooleanArray?
    private val values: IntArray
    private val index: UniqueIndex<Int>

    constructor(values: IntArray) {
        name = null
        this.values = values
        index = IntegerRangeUniqueIndex(0, values.size - 1)
        available = null
    }

    constructor(name: Any?, values: IntArray) {
        this.name = name
        this.values = values
        index = IntegerRangeUniqueIndex(0, values.size - 1)
        available = null
    }

    constructor(values: IntArray, available: BooleanArray?) {
        name = null
        this.values = values
        index = IntegerRangeUniqueIndex(0, values.size - 1)
        this.available = available
    }

    private constructor(values: IntArray, index: UniqueIndex<Int>) {
        name = null
        this.values = values
        this.index = index
        available = null
    }

    override val type: KClass<Any>
        get() = Int::class as KClass<Any>

    override operator fun get(key: Int): Int? {
        return if (isNull(key)) null else values[key]
    }

    fun isNull(key: Int): Boolean {
        return available != null && !available[key]
    }

    fun getDouble(key: Int): Double {
        return values[key].toDouble()
    }

    override fun getKey(i: Int): Int {
        return index.getKey(i)
    }

    override fun size(): Int {
        return index.size
    }

    override fun getAddress(key: Int): Int {
        return index.getAddress(key)
    }

    override operator fun set(key: Int, value: Int?) {
        if(value != null) {
            values[key] = value
            if(available != null) {
                available[key] = true
            } else {
                // ToDo: Exception?
            }
        } else {
            if(available != null) {
                available[key] = false
            } else {
                // ToDo: Exception?
            }
        }
    }

    override fun keys(): Iterable<Int> {
        return index.keys()
    }

    fun <L> reindex(vararg keys: L): Series<L, Int>? {
        return null
    }

    fun head(count: Int): Series<Int, Int>? {
        return reindex(index.head(count))
    }

    fun tail(count: Int): Series<Int, Int>? {
        return reindex(index.tail(count))
    }

    fun <K> reindex(index: UniqueIndex<K>?): Series<K, Int>? {
//        return new IndexedSeries<K,Double>(getName(), values, index);
        return null
    }
}
